<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div v-else-if="is_data_ready">
        <!--  Content Start -->
        <div class="autho">
          <div class="container">
            <div class="row">
              <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                <div class="sabc_auth_news_sec">
                  <div class="sabc_auth_news_row">
                    <h1>{{author.display_name}}</h1>
                    <div class="sabc_auth_news_pic" v-if="author.image">
                        <img :src="$resizeImage(author.image.image_path,368,232)"  width="368" height="232" alt="Profile Picture"/>
                    </div>
                    <div class="sabc_auth_news_desc">
                        <p v-if="author.role"><b>Role:</b>{{author.role.name}}</p>
                        <p><b>Articles Written:</b> {{paginate['total']}}</p>
                        <p v-if="author.twitter">
                            <a :href="author.twitter" target="_blank">
                                <img :src="require('@/assets/images/sabc_social_twitter.webp')" alt="twitter"/>
                            </a>
                        </p>
                        <p class="sabc_auth_news_bio_desk"><b>Bio:</b>{{author.biography}}</p>
                    </div>
                    <p class="sabc_auth_news_bio_mobi"><b>Bio:</b>{{author.biography}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section class="sabc_tier_sports_sec">
            <div class="container">
                <h2 class="sabc_tier_sports_head">Articles By {{author.display_name}}</h2>
                <div class="row">
                    <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div class="sabc_tier_news_sec" v-for="article in relatedArticles" v-bind:key="article.id">
                            <div class="sabc_tier_news_row">
                                <div class="sabc_tier_news_pic" v-if="article.sport.slug != 'main-domain'">
                                    <router-link :to="{ name: 'news_detail', params: { sport: article.sport.slug, news: 'news',slug:article.slug}}">
                                        <img :src="$resizeImage(article.image.image_path,368,232)"  width="368" height="232" alt="sabc_tier_sport_news_pic"/>
                                    </router-link>
                                </div>
                                <div class="sabc_tier_news_pic" v-else>
                                    <router-link :to="{ name: 'main_news_detail', params: {news: 'news',slug:article.slug}}">
                                        <img :src="$resizeImage(article.image.image_path,368,232)"  width="368" height="232" alt="sabc_tier_sport_news_pic"/>
                                    </router-link>
                                </div>
                                <div class="sabc_tier_news_desc">
                                    <h2>
                                        <router-link :to="{ name: 'news_detail', params: { sport: article.sport.slug, news: 'news',slug:article.slug}}">
                                            {{article.headline}}
                                        </router-link>
                                    </h2>
                                    <router-link :to="{ name: 'news_detail', params: { sport: article.sport.slug, news: 'news',slug:article.slug}}">
                                        <p v-html="article.stand_first_simple">
                                        </p>
                                    </router-link>
                                    <label>
                                        {{article.start_date | formatDate2}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button @click="getAuthorData(offset+1)" class="btn btn-primary mb-5 mt-2" :class="[{'invisible' : (paginate['current_page'] == Math.ceil(paginate['total']/9)) }]">Load More</button>
                        </div>
                    </div>
                    <SideBar :editorsPick="editorsPick" :bannerImages="bannerImages" :bannerEmbed="bannerEmbed"></SideBar>
                </div>
            </div>
        </section>
        <!--  Content end -->
    </div>
</template>

<script>
    import EditorPicks from '@/components/common/editor-pick';
    import SideBar from '@/components/common/sidebar/sidebar';
    import moment from 'moment';

    export default {
        name: 'author',
        data() {
            return {
                author: [],
                relatedArticles: [],
                editorsPick: [],
                bannerImages: [],
                bannerEmbed: [],
                is_data_ready: false,
                startDate: ',',
                offset : 1,
                paginate : [],
            }
        },
        components: {
            EditorPicks, SideBar
        },
        mounted() {
            this.getAuthorData(this.offset);

        },
        serverPrefetch() {
            return this.getAuthorData(this.offset);
        },
        computed: {
            keywords() {
                return this.$getSafe(() => this.sportNewsDetail.subjects.map((subj) => subj.name).join(','))
            }
        },
        watch: {
            $route(to, from) {
                this.getAuthorData(this.offset);
            }
        },
        jsonld() {
            if (isServer)
                return {
                    "@context": "http://schema.org",
                    "@graph": []
                }

            if (!this.relatedArticles)
                return

            let events = [];
            this.relatedArticles.forEach((article) => {
                let path = this.$siteURL.replace(/\/+$/, '') + article.articleSection.section.slug+article.slug;

                events.push(
                    {
                        '@type': 'NewsArticle',
                        mainEntityOfPage: {
                            '@type': 'WebPage',
                            '@id': path
                        },
                        headline:  article.headline,
                        description: article.meta_description,
                        image:  article.image.image_path,
                        author: {
                            '@type': 'Person',
                            name: article.author.display_name,
                            "url" : this.$siteURL
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: "SABC",
                            logo: {
                                '@type': 'ImageObject',
                                url: this.$siteURL + 'public/images/logo.png'
                            }
                        },
                        datePublished: article.created_at,
                        dateModified: article.updated_at
                    }
                )
            });
            return {
                "@context": "http://schema.org",
                "@graph": events
            };
        },
        watch: {
            '$route': function (to, from) {
                this.relatedArticles = [];
                this.offset = 1;
                this.getAuthorData(this.offset);
            }
        },
        metaInfo() {

            var ss = this.$store.state.siteSetting;

            var replacements = {
                "%AUTHOR_NAME%": this.author.display_name,
            };
            let title = this.$getSafe(() => this.$replacePlaceHolders(ss['author_meta_title'],replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss['author_meta_description'],replacements));
            let keywords = this.$getSafe(() => this.$replacePlaceHolders(ss['author_meta_keywords'],replacements))


            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link: [
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                ],
            }
        },

        methods: {
            moment: function () {
                return moment();
            },

            getAuthorData(offset) {
                this.offset = offset;
                if(this.offset == 1){
                    this.is_data_ready = false;
                }
                return this.$axios.get(this.$GetAuthorData + '?slug=' + this.$route.params.slug+ '&page=' + offset)
                    .then(response => {
                        if (response.data) {
                            this.author = response.data.author;
                            this.relatedArticles = this.relatedArticles.concat(response.data.relatedNews.data);
                            // this.relatedArticles = response.data.relatedNews.data;
                            this.editorsPick = response.data.editorsPick;
                            this.paginate['total'] = response.data.relatedNews.total;
                            this.paginate['current_page'] = response.data.relatedNews.current_page;
                            this.is_data_ready = true;
                        }
                    })
                    .catch(error => {
                    })
            },
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/tier_sports.scss';
@import '~@/assets/scss/right_sidebar.scss';
</style>
